<template>
  <section>
    <div class="row h-100 items">
        <div class="col-md-12">
          <div class="form-group">
            <label for="item_description">Email address that will receive notification for successful backup</label>
              <section v-for="(email, index) in success_emails" :key="`success_emails-${{ index }}`">
                <div class="row">
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      required
                      name="success_emails[]"
                      aria-describedby="tpsuccess_emails"
                      placeholder="Success Emails"
                      v-model="email.email"
                    />
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <button
                        @click="addSuccessEmail"
                        class="add-btn btn btn-primary"
                        type="button"
                      >
                        +</button
                      ><button
                      v-if="success_emails.length > 1"
                        class="remove-btn btn btn-danger"
                        type="button"
                        @click="removeSuccessEmail(index)"
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
    </div>
    <div class="row h-100 items">
        <div class="col-md-12">
          <div class="form-group">
            <label for="item_description">Email address that will receive notification for failed backup</label>
              <section v-for="(email, index) in failed_emails" :key="`failed_emails-${{ index }}`">
                <div class="row">
                  <div class="col">
                    <input
                      type="email"
                      class="form-control"
                      required
                      name="failed_emails[]"
                      aria-describedby="tpfailed_emails"
                      placeholder="Failed Emails"
                      v-model="email.email"
                    />
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <button
                        @click="addFailedEmail"
                        class="add-btn btn btn-primary"
                        type="button"
                      >
                        +</button
                      ><button
                      v-if="failed_emails.length > 1"
                        id="remove-btn-'+counter+'"
                        class="remove-btn btn btn-danger"
                        type="button"
                        @click="removeFailedEmail(index)"
                      >
                        -
                      </button>
                    </div>
                  </div>
                </div>
              </section>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["success_emails_prop", "failed_emails_prop"],
  components: {
    //
  },
  data() {
    return {
      value: "",
      success_emails: [],
      failed_emails: [],
      search_term: '',
    };
  },
  mounted() {
    this.success_emails = this.success_emails_prop
    if(!this.success_emails || this.success_emails.length == 0){
      this.addSuccessEmail()
    }
    this.failed_emails = this.failed_emails_prop
    if(!this.failed_emails || this.failed_emails.length == 0){
      this.addFailedEmail()
    }
  },
  methods: {
    addFailedEmail() {
      this.failed_emails.push({
        email: "",
      });
    },
    addSuccessEmail() {
      this.success_emails.push({
        email: "",
      });
    },
    removeFailedEmail(index) {
      this.failed_emails.splice(index, 1);
    },
    removeSuccessEmail(index) {
      this.success_emails.splice(index, 1);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>