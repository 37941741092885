<template>
  <section>
    <div class="row pb-3">
      <div class="col">
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline1"
            class="custom-control-input"
            value="package_search"
            v-model="search_type"
            :checked="search_type == 'package_search'"
          />
          <label class="custom-control-label" :for="`customRadioInline1`"
            >Package Search</label
          >
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input
            type="radio"
            id="customRadioInline2"
            class="custom-control-input"
            value="item_search"
            v-model="search_type"
            :checked="search_type == 'item_search'"
          />
          <label class="custom-control-label" :for="`customRadioInline2`"
            >Item Search</label
          >
        </div>
      </div>
    </div>
    <div class="row h-100 items">
      <div class="col-md-12 col-12" v-if="search_type == 'package_search'">
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label for="item_description">Package Description</label>
              <multiselect
                v-model="item.package_code"
                id="ajax"
                label="package_description"
                track-by="package_guid"
                placeholder="Type to search"
                open-direction="bottom"
                :options="packages"
                :multiple="false"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :limit="100"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="true"
                :hide-selected="false"
                @search-change="asyncPackageFind"
                @input="setPackageVal(item)"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>
                      {{ option.package_description }}
                    </span>
                    <span class="custom__remove" @click="remove(option)">❌</span>
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                  <div class="multiselect__clear" v-if="item.package_code.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="form-group">
              <label for="package_code">Package Code</label>
              <input
              readonly
              v-if="item.package_code"
                type="text"
                class="form-control"
                required
                :name="`item[package_code]`"
                aria-describedby="tppackage_code"
                placeholder="Package Code"
                v-model="item.package_code.package_code"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-12" v-else-if="search_type == 'item_search'">
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label for="item_description">Item Description</label>
              <multiselect
                v-model="item.item_code"
                id="ajax"
                label="item_description"
                track-by="item_guid"
                placeholder="Type to search"
                open-direction="bottom"
                :options="waterDispensers"
                :multiple="false"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :limit="100"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="true"
                :hide-selected="true"
                @search-change="asyncFind"
                @input="setItemVal()"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="custom__tag">
                    <span>
                      {{ option.item_description }}
                    </span>
                    <span class="custom__remove" @click="remove(option)">❌</span>
                  </span>
                </template>
                <template slot="clear" slot-scope="props">
                  <div class="multiselect__clear" v-if="waterDispensers.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                </template>
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="search_type == 'item_search' && chosenItem && chosenItem.item_guid">
      <div class="col">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Location</th>
              <th scope="col">Item Code</th>
              <th scope="col">Bal Qty</th>
              <th scope="col">Retail Price</th>
              <th scope="col">Photo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{chosenItem.item_description}}</td>
              <td>
                <span v-html="chosenItem.location"></span>
              </td>
              <td>{{chosenItem.item_code}}</td>
              <td>{{chosenItem.balance_quantity ? (Math.round(chosenItem.balance_quantity)).toFixed(4) : ''}}</td>
              <td>{{chosenItem.item_price}}</td>
              <td>
                <div v-if="chosenItem.item_photo">
                  <img class="img" style="width:150px" :src="chosenItem.item_photo" @click="setPhoto(chosenItem.item_photo)">
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="search_type == 'package_search' && package_items && package_items.length > 0">
      <div class="col">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Location</th>
              <th scope="col">Item Code</th>
              <th scope="col">Photo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,index) in package_items" :key="`package-${index}`">
              <td>{{item.item_description}}</td>
              <td>
                <span v-html="item.item_location"></span>
              </td>
              <td>{{item.item_code}}</td>
              <td>
                <img class="img img-fluid" :src="`${item.item_photo ? 'data:image/png;base64,'+item.item_photo : '/img/no-image-png-5-Transparent-Images.png'}`">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="showBigImage" tabindex="-1" role="dialog"
        aria-labelledby="showBigImageTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="showBigImageTitle">Image</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div>
                      <img class="img img-fluid" :src="item_photo">
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    //
  },
  data() {
    return {
      value: "",
      chosenItem: [],
      package_items: [],
      waterDispensers: [],
      packages: [],
      options: [],
      isLoading: false,
      item_photo: '',
      search_term: '',
      search_type: 'item_search',
      item:
        {
          description: "",
          quantity: "",
          remark: "",
          item_code: [],
          package_code: [],
          package_items: [],
          item_type: 'item_search',
        },
    };
  },
  mounted() {
    console.log("Component mounted.");
  },
  methods: {
    setPhoto(item_photo){
      this.item_photo = item_photo
      $('#showBigImage').modal('show')
    },
    addItem() {
      this.items.push({
        description: "",
        quantity: "",
        remark: "",
        item_code: [],
        package_code: [],
        package_items: [],
        item_type: 'item_search',
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    limitText (count) {
      return `and ${count} other waterDispensers`
    },
    asyncFind (query) {
      this.search_term = query
      if(query && query != ''){
        this.isLoading = true
        axios
          .get("/ajax/search-ac?parameter=" + query)
          .then((response) => {
            if(this.search_term == response.data.search_term){
              this.waterDispensers = response.data.data;
              this.isLoading = false
            }
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    asyncPackageFind (query) {
      this.search_term = query
      if(query && query != ''){
        this.isLoading = true
        axios
          .get("/ajax/search-ac-package?parameter=" + query)
          .then((response) => {
            if(this.search_term == response.data.search_term){
              this.packages = response.data.data;
              this.isLoading = false
            }
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    clearAll () {
      this.selectedCountries = []
    },
    packageQtyChange(index1){
      if(this.items[index1].package_items){
        for (let index = 0; index < this.items[index1].package_items.length; index++) {
          this.items[index1].package_items[index].item_quantity = this.items[index1].package_items[index].retrieved_qty ? this.items[index1].package_items[index].retrieved_qty * this.items[index1].quantity : ''
        }
      }
    },
    setItemVal(){
      this.waterDispensers = []
      this.isLoading = true
      var search = this.item.item_code.item_guid
      axios
        .get("/ajax/item-product-details?parameter=" + search)
        .then((response) => {
          this.chosenItem = response.data.data
          this.isLoading = false
        })
        .catch((e) => {
          console.log(e)
        });
    },
    setPackageVal(item){
      this.packages = []
      if(item && item.package_code && item.package_code.package_dockey != ''){
        this.isLoading = true
        var search = item.package_code.package_dockey
        axios
          .get("/ajax/search-ac-package-item?parameter=" + search)
          .then((response) => {
            this.package_items = response.data.data;
            // for (let index = 0; index < this.package_items.length; index++) {
            //   package_items[index].retrieved_qty = item.package_items[index].item_quantity ? item.package_items[index].item_quantity : ''
            //   item.package_items[index].item_quantity = item.package_items[index].retrieved_qty ? item.package_items[index].retrieved_qty * item.quantity : ''
            // }
            this.isLoading = false
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    removePackageItem(index,index1){
      this.items[index].package_items.splice(index1, 1);
    },
    retrieveItemCode() {
      axios
        .get("/ajax/search-ac?parameter=" + this.items[index].item_code)
        .then(({ data }) => {
          var a = [];
          for (var i = 0; i < data.length; i++) {
            var temp = { value: data[i]["id"], name: data[i]["name"] };
            a.push(temp);
          }
          this.customers = a;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>