<template>
  <section>
    <div class="row pb-3">
      <div class="col-md-7">
        <div class="form-group">
          <label for="item_description">Item Serial Number</label>
          <multiselect
            v-model="item_serial_numbers_select"
            tag-placeholder="Choose this as the serial number"
            placeholder="Search or add a serial number"
            label="serial_number"
            track-by="serial_number"
            :options="serialnumbers"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            @select="updateModel"
            @remove="removeVal"
          ></multiselect>
          <input
            type="hidden"
            class="form-control"
            :name="`item_serial_number[]`"
            aria-describedby="tpitem_description"
            v-model="item_serial_numbers"
          />
        </div>
      </div>
      <div class="col-md-3 col-sm-12" v-if="isMobile()">
        <div id="qr-code-full-region"></div>
      </div>
      <div class="col-md-3 col-sm-12" v-else>
        <div id="qr-code-full-region"></div>
      </div>
    </div>
    <div
      v-for="(msg, index) in message"
      :key="index"
      style="white-space: pre-line"
    >
      <pre>decodedResult => {{ msg.decodedResult }}</pre>
    </div>
  </section>
</template>

<script>
import { Html5QrcodeScanner } from "html5-qrcode";
export default {
  props: ["serialnumbers"],
  components: {
    //
  },
  data() {
    return {
      value: "",
      countries: [],
      packages: [],
      options: [],
      item_serial_numbers: [],
      item_serial_numbers_select: [],
      isLoading: false,
      item_type: 'type',
      search_term: "",
      scanned: false,
      message: [],
      items: [
        {
          description: "",
          quantity: "",
          remark: "",
          item_code: [],
          package_code: [],
          package_items: [],
        },
      ],
    };
  },
  mounted() {
    console.log("Component mounted.");
    console.log(this.items);
    this.countries = this.itemcode;
    this.items = this.orderitems;
    // this.refreshScanner();
    this.creatScan();
  },
  methods: {
    creatScan() {
      const config = { fps: 10, qrbox: 250 ,aspectRatio: 2};
      const html5QrcodeScanner = new Html5QrcodeScanner(
        "qr-code-full-region",
        config
      );
      html5QrcodeScanner.render(this.onScanSuccess);
      html5QrcodeScanner.applyVideoConstraints({ focusMode: "continuous", advanced: [ {zoom: isAndroid ? 1.5 : 2.0 } ]})
    },
    onScanSuccess(decodedText, decodedResult) {
      this.scanSuccessFile(decodedText)
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    refreshScanner(){
      var html5QrcodeScanner = new Html5QrcodeScanner(
            "qr-reader", { fps: 10, qrbox: { width: 400, height: 250 },experimentalFeatures: {useBarCodeDetectorIfSupported: true}, });
        html5QrcodeScanner.render(onScanSuccess);
        var that = this
        html5QrcodeScanner.applyVideoConstraints({ focusMode: "continuous", advanced: [ {zoom: isAndroid ? 1.5 : 2.0 } ]})
        function onScanSuccess(decodedText, decodedResult) {
          console.log(`Code scanned = ${decodedText}`, decodedResult);
          that.scanSuccessFile(decodedText)
        }
    },
    scanSuccessFile(decodedText){
      this.scanned = true
      this.addTag(decodedText)
    },
    removeVal(tag){
      console.log(tag)
      const index = this.item_serial_numbers.indexOf(tag.serial_number);
      if (index > -1) {
        this.item_serial_numbers.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    updateModel(newTag){
      this.item_serial_numbers.push(newTag.serial_number)
      this.serialnumbers.push(newTag)
    },
    addTag (newTag) {
      const tag = {
        serial_number: newTag,
        ItemCode: '',
      }
      var exist = false;
      for (let index = 0; index < this.item_serial_numbers_select.length; index++) {
        if(this.item_serial_numbers_select[index].serial_number == newTag){
          exist = true
        }
      }
      if(!exist){
        if(this.scanned){
          var audio = new Audio('/audio/Barcode-scanner-beep-sound.mp3'); // path to file
          audio.play();
          this.scanned = false
        }
        this.serialnumbers.push(tag)
        this.item_serial_numbers_select.push(tag)
        this.item_serial_numbers.push(newTag)
      }else{
        // alert('Serial Number already chosen')
      }
      this.refreshScanner()
    },
    addItem() {
      console.log("test");
      this.items.push({
        description: "",
        quantity: "",
        remark: "",
        item_code: [],
        package_code: [],
        package_items: [],
      });
    },
    setSerialNoVal(){
      this.item_serial_numbers = ''
      for (let index = 0; index < this.item_serial_numbers_select.length; index++) {
        this.item_serial_numbers += this.item_serial_numbers_select[index].serial_number+', '
      }
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    limitText(count) {
      return `and ${count} other countries`;
    },
    asyncFind(query) {
      this.search_term = query;
      if (query && query != "") {
        this.isLoading = true;
        axios
          .get("/ajax/search-ac?parameter=" + query)
          .then((response) => {
            if (this.search_term == response.data.search_term) {
              this.countries = response.data.data;
              this.isLoading = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    asyncPackageFind(query) {
      this.search_term = query;
      if (query && query != "") {
        this.isLoading = true;
        axios
          .get("/ajax/search-ac-package?parameter=" + query)
          .then((response) => {
            if (this.search_term == response.data.search_term) {
              this.packages = response.data.data;
              this.isLoading = false;
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    clearAll() {
      this.selectedCountries = [];
    },
    packageQtyChange(index1) {
      if (this.items[index1].package_items) {
        for (
          let index = 0;
          index < this.items[index1].package_items.length;
          index++
        ) {
          this.items[index1].package_items[index].item_quantity = this.items[
            index1
          ].package_items[index].item_quantity
            ? this.items[index1].package_items[index].item_quantity *
              this.items[index1].quantity
            : "";
        }
      }
    },
    setItemVal() {
      this.countries = [];
    },
    setPackageVal(item) {
      this.packages = [];
      if (item && item.package_code && item.package_code.package_dockey != "") {
        this.isLoading = true;
        var search = item.package_code.package_dockey;
        axios
          .get("/ajax/search-ac-package-item?parameter=" + search)
          .then((response) => {
            for (let index = 0; index < item.package_items.length; index++) {
              item[index1].package_items[index].item_quantity = item
                .package_items[index].item_quantity
                ? item.package_items[index].item_quantity * item.quantity
                : "";
            }
            item.package_items = response.data.data;
            this.isLoading = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    retrieveItemCode() {
      axios
        .get("/ajax/search-ac?parameter=" + this.items[index].item_code)
        .then(({ data }) => {
          var a = [];
          for (var i = 0; i < data.length; i++) {
            var temp = { value: data[i]["id"], name: data[i]["name"] };
            a.push(temp);
          }
          this.customers = a;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>