<template>
  <section>
    <div class="row h-100 items">
        <div class="col-md-12">
          <div class="form-group">
            <label for="item_description">Show Invoice Number for the Following Order Type</label>
            <multiselect
              v-model="item_order_types_select"
              tag-placeholder="Choose this"
              placeholder="Search or add a order type"
              label="doc_types"
              track-by="doc_types"
              :options="doctypes"
              :multiple="true"
              :taggable="true"
              @select="updateModel"
              @remove="removeVal"
            ></multiselect>
            <input
              type="hidden"
              class="form-control"
              :name="`item_order_type[]`"
              aria-describedby="tpitem_description"
              v-model="item_order_types"
            />
          </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["ordertypes"],
  components: {
    //
  },
  data() {
    return {
      value: "",
      success_emails: [],
      failed_emails: [],
      search_term: '',
      item_order_types: [],
      item_order_types_select: [],
      doctypes:[
        {doc_types:"Packing",},
        {doc_types:"Repair",},
        {doc_types:"Return",},
        {doc_types:"Replacement",},
        {doc_types:"Assemble",},
        {doc_types:"Point",},
        {doc_types:"Outdoor Service",},
        {doc_types:"Dispatch",},
        {doc_types:"Stock Receive",},
        {doc_types:"Others",},
      ]
    };
  },
  mounted() {
    console.log('as')
    if(!this.ordertypes){
      // this.ordertypes = []
    }else{
      this.item_order_types = this.ordertypes
      for (let index = 0; index < this.ordertypes.length; index++) {
        this.item_order_types_select.push({
          doc_types:this.ordertypes[index]
        })
      }
    }
  },
  methods: {
    removeVal(tag){
      console.log(tag)
      const index = this.item_order_types.indexOf(tag.doc_types);
      if (index > -1) {
        this.item_order_types.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    updateModel(newTag){
      console.log(newTag)
      this.item_order_types.push(newTag.doc_types)
      // this.item_order_types_select.push(newTag)
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>