require('./bootstrap');
window.Vue = require("vue");
import Multiselect from "vue-multiselect";

// register globally
Vue.component("multiselect", Multiselect);
Vue.component(
    "product-form",
    require("./components/ProductForm.vue").default
);
Vue.component(
    "order-itemp",
    require("./components/OrderItemp.vue").default
);
Vue.component(
    "order-item",
    require("./components/OrderItem.vue").default
);
Vue.component(
    "order-item-editp",
    require("./components/OrderItemEditp.vue").default
);
Vue.component(
    "order-item-edit",
    require("./components/OrderItemEdit.vue").default
);
Vue.component(
    "serial-number",
    require("./components/SerialNumber.vue").default
);
Vue.component(
    "serial-number-new",
    require("./components/SerialNumberNew.vue").default
);
Vue.component(
    "show-invoice",
    require("./components/ShowInvoiceNumber.vue").default
);
Vue.component(
    "order-item-clone",
    require("./components/OrderItemClone.vue").default
);
Vue.component(
    "notification-email",
    require("./components/NotificationEmail.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
});
// require('alpinejs');

/*!
 * Start Bootstrap - SB Admin v6.0.2 (https://startbootstrap.com/template/sb-admin)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-sb-admin/blob/master/LICENSE)
 */
(function ($) {
    "use strict";

    // Add active state to sidbar nav links
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
    $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function () {
        if (this.href === path) {
            $(this).addClass("active");
        }
    });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function (e) {
        e.preventDefault();
        $("body").toggleClass("sb-sidenav-toggled");
    });
})(jQuery);
