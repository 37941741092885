<template>
  <section>
    <section v-for="(item, index) in items" :key="`item-${{ index }}`"
      style="border-bottom: 1px dashed #424242;">
      <div class="row pb-3">
        <div class="col">
          <div class="form-group">
              <h4 :id="`item-number-'${index}'`" class="item-number">
                {{ index + 1 }}
              </h4>
            </div>
        </div>
      </div>
      <div class="row pb-3">
        <div class="col">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              :key="`package-item_type-${item.item_type}`"
              type="radio"
              :id="`customRadioInline1-${index}`"
              :name="`customRadioInline1-${index}`"
              class="custom-control-input"
              value="package_search"
              v-model="item.item_type"
              :checked="item.item_type == 'package_search'"
            />
            <label class="custom-control-label" :for="`customRadioInline1-${index}`"
              >Package Search</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              :key="`search-item_type-${item.item_type}`"
              type="radio"
              :name="`customRadioInline1-${index}`"
              :id="`customRadioInline2-${index}`"
              class="custom-control-input"
              value="item_search"
              v-model="item.item_type"
              :checked="item.item_type == 'item_search'"
            />
            <label class="custom-control-label" :for="`customRadioInline2-${index}`"
              >Item Search</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              :key="`type-item_type-${item.item_type}`"
              type="radio"
              :id="`customRadioInline3-${index}`"
              :name="`customRadioInline1-${index}`"
              class="custom-control-input"
              value="type"
              v-model="item.item_type"
              :checked="item.item_type == 'type'"
            />
            <label class="custom-control-label" :for="`customRadioInline3-${index}`"
              >Type</label
            >
          </div>
        </div>
      </div>
      <div class="row h-100 items">
        <div class="col-md-10 col-12" v-if="item.item_type == 'package_search'">
          <div class="row">
            <div class="col-md-7">
              <div class="form-group">
                <label for="item_description">Package Description</label>
                <multiselect
                  v-model="item.package_code"
                  id="ajax"
                  label="package_description"
                  track-by="package_guid"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="packages"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="100"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="false"
                  @search-change="asyncPackageFind"
                  @input="setPackageVal(item)"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <span>
                        {{ option.package_description }}
                      </span>
                      <span class="custom__remove" @click="remove(option)">❌</span>
                    </span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="item.package_code.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="form-group">
                <label for="item_quantity">Quantity</label>
                <input
                  type="number"
                  min="1"
                  step="any"
                  class="form-control"
                  required
                  :name="`item[${index}][item_quantity]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Quantity"
                  v-model="item.quantity"
                  @change="packageQtyChange(index)"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="package_code">Package Code</label>
                <input
                readonly
                v-if="item.package_code"
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][package_code]`"
                  aria-describedby="tppackage_code"
                  placeholder="Package Code"
                  v-model="item.package_code.package_code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_barcode">Barcode</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_barcode]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Bar Code"
                  v-model="item.package_code.package_barcode"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Remark</label>
                <textarea
                  :name="`item[${index}][remark]`"
                  class="form-control"
                  id="description"
                  rows="5"
                  v-model="item.remark"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row bg-subitem" v-for="(pitem,index1) in item.package_items" :key="`pitem-${index1}`">
            <div class="col">
              <input type="hidden" :name="`item[${index}][package][${index1}][item_unique]`" v-model="pitem.item_guid">
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group">
                    <label for="item_description">Item Description</label>
                    <input
                    readonly
                      type="text"
                      class="form-control"
                      required
                      :name="`item[${index}][package][${index1}][item_description]`"
                      aria-describedby="tpitem_description"
                      placeholder="Item Description"
                      v-model="pitem.item_description"
                    />
                  </div>
                </div>
                <div class="col-md-2 col-12">
                  <div class="form-group">
                    <label for="item_quantity">Quantity</label>
                    <input
                      type="number"
                      min="1"
                      step="any"
                      class="form-control"
                      required
                      :name="`item[${index}][package][${index1}][item_quantity]`"
                      aria-describedby="tpitem_quantity"
                      placeholder="Quantity"
                      v-model="pitem.item_quantity"
                    />
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <div class="form-group">
                    <label for="item_quantity">Item Code</label>
                    <input
                    readonly
                      type="text"
                      class="form-control"
                      required
                      :name="`item[${index}][package][${index1}][item_code]`"
                      aria-describedby="tpitem_quantity"
                      placeholder="Item Code"
                      v-model="pitem.item_code"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-12">
                  <div class="form-group">
                    <label for="item_quantity">Barcode</label>
                    <input
                    readonly
                      type="text"
                      class="form-control"
                      required
                      :name="`item[${index}][package][${index1}][item_barcode]`"
                      aria-describedby="tpitem_barcode"
                      placeholder="Bar Code"
                      v-model="pitem.barcode"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="item_quantity">Item Photo</label>
                    <br>
                    <img v-if="pitem.item_photo && pitem.item_photo != ''" class="img img-fluid" :src="`data:image/png;base64,${pitem.item_photo}`" style="max-width:180px !important">
                    <img v-else class="img img-fluid" src="/img/no-image-png-5-Transparent-Images.png" style="max-width:180px !important">
                  </div>
                </div>
                <div class="col-md-3 col-12">
                  <button class="remove-btn btn btn-danger" type="button" @click="removePackageItem(index,index1)">
                    -
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-12" v-else-if="item.item_type == 'item_search'">
          <div class="row">
            <div class="col-md-7">
              <div class="form-group">
                <label for="item_description">Item Description</label>
                <multiselect
                  v-model="item.item_code"
                  id="ajax"
                  label="item_description"
                  track-by="item_guid"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="countries"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="100"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFind"
                  @input="setItemVal()"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <span>
                        {{ option.item_description }}
                      </span>
                      <span class="custom__remove" @click="remove(option)">❌</span>
                    </span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="item.item_code.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="form-group">
                <label for="item_quantity">Quantity</label>
                <input
                  type="number"
                  min="1"
                  step="any"
                  class="form-control"
                  required
                  :name="`item[${index}][item_quantity]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Quantity"
                  v-model="item.quantity"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Item Code</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_code]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Item Code"
                  v-model="item.item_code.item_code"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Barcode</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_barcode]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Bar Code"
                  v-model="item.item_code.barcode"
                />
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="form-group">
                <label for="item_quantity">Item Photo</label>
                <br>
                <img v-if="item.item_code.item_photo && item.item_code.item_photo !=''" class="img" :src="`data:image/png;base64,${item.item_code.item_photo}`" style="max-width:180px !important">
                <img v-else class="img img-fluid" src="/img/no-image-png-5-Transparent-Images.png" style="max-width:180px !important">
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Remark</label>
                <textarea
                  :name="`item[${index}][remark]`"
                  class="form-control"
                  id="description"
                  rows="5"
                  v-model="item.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-10 col-12" v-else>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="item_description">Item Description</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_description]`"
                  aria-describedby="tpitem_description"
                  placeholder="Item Description"
                  v-model="item.item_description"
                  v-if="item.item_type == 'type'"
                />
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="form-group">
                <label for="item_quantity">Quantity</label>
                <input
                  type="number"
                  min="1"
                  step="any"
                  class="form-control"
                  required
                  :name="`item[${index}][item_quantity]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Quantity"
                  v-model="item.quantity"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="name">Remark</label>
                <textarea
                  :name="`item[${index}][remark]`"
                  class="form-control"
                  id="description"
                  rows="5"
                  v-model="item.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12 pt-3">
          <div class="form-group">
            <button
              @click="addItem"
              class="add-btn btn btn-primary"
              type="button"
            >
              +</button
            ><button
            v-if="items.length > 1"
              id="remove-btn-'+counter+'"
              class="remove-btn btn btn-danger"
              type="button"
              @click="removeItem(index)"
            >
              -
            </button>
          </div>
        </div>
        <input v-if="item.item_type == 'package_search'" type="hidden" :name="`item[${index}][package_guid]`" v-model="item.package_code.package_guid">
        <input v-else-if="item.item_type == 'item_search'" type="hidden" :name="`item[${index}][item_guid]`" v-model="item.item_code.item_guid">
      </div>
    </section>
  </section>
</template>

<script>
export default {
  components: {
    //
  },
  data() {
    return {
      value: "",
      countries: [],
      packages: [],
      options: [],
      isLoading: false,
      search_term: '',
      items: [
        {
          description: "",
          quantity: "",
          remark: "",
          item_code: [],
          package_code: [],
          package_items: [],
          item_type: 'item_search',
        },
      ],
    };
  },
  mounted() {
    console.log("Component mounted.");
  },
  methods: {
    addItem() {
      this.items.push({
        description: "",
        quantity: "",
        remark: "",
        item_code: [],
        package_code: [],
        package_items: [],
        item_type: 'item_search',
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    limitText (count) {
      return `and ${count} other countries`
    },
    asyncFind (query) {
      this.search_term = query
      if(query && query != ''){
        this.isLoading = true
        axios
          .get("/ajax/search-ac?parameter=" + query)
          .then((response) => {
            if(this.search_term == response.data.search_term){
              this.countries = response.data.data;
              this.isLoading = false
            }
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    asyncPackageFind (query) {
      this.search_term = query
      if(query && query != ''){
        this.isLoading = true
        axios
          .get("/ajax/search-ac-package?parameter=" + query)
          .then((response) => {
            if(this.search_term == response.data.search_term){
              this.packages = response.data.data;
              this.isLoading = false
            }
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    clearAll () {
      this.selectedCountries = []
    },
    packageQtyChange(index1){
      if(this.items[index1].package_items){
        for (let index = 0; index < this.items[index1].package_items.length; index++) {
          this.items[index1].package_items[index].item_quantity = this.items[index1].package_items[index].retrieved_qty ? this.items[index1].package_items[index].retrieved_qty * this.items[index1].quantity : ''
        }
      }
    },
    setItemVal(){
      this.countries = []
    },
    setPackageVal(item){
      this.packages = []
      if(item && item.package_code && item.package_code.package_dockey != ''){
        this.isLoading = true
        var search = item.package_code.package_dockey
        axios
          .get("/ajax/search-ac-package-item?parameter=" + search)
          .then((response) => {
            if(!item.quantity){
              item.quantity = 1
            }
            item.package_items = response.data.data;
            for (let index = 0; index < item.package_items.length; index++) {
              item.package_items[index].retrieved_qty = item.package_items[index].item_quantity ? item.package_items[index].item_quantity : ''
              item.package_items[index].item_quantity = item.package_items[index].retrieved_qty ? item.package_items[index].retrieved_qty * item.quantity : ''
            }
            this.isLoading = false
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    removePackageItem(index,index1){
      this.items[index].package_items.splice(index1, 1);
    },
    retrieveItemCode() {
      axios
        .get("/ajax/search-ac?parameter=" + this.items[index].item_code)
        .then(({ data }) => {
          var a = [];
          for (var i = 0; i < data.length; i++) {
            var temp = { value: data[i]["id"], name: data[i]["name"] };
            a.push(temp);
          }
          this.customers = a;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>