<template>
  <section>
    <section v-for="(item, index) in items" :key="`item-${items.length+'-'+index}`">
      <div class="row pb-3">
        <div class="col">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" :id="`customCheck${index}`" v-model="item.typing">
            <label class="custom-control-label" :for="`customCheck${index}`">Switch to typing</label>
          </div>
        </div>
      </div>
      <div class="row h-100 items">
        <div class="col-md-1 col-12 pt-3">
          <div class="form-group">
            <p :id="`item-number-'${index}'`" class="item-number">
              {{ index + 1 }}
            </p>
          </div>
        </div>
        <div class="col-md-9 col-12">
          <div class="row">
            <div class="col-md-7 col-12">
              <div class="form-group">
                <label for="item_description">Item Description</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_description]`"
                  aria-describedby="tpitem_description"
                  placeholder="Item Description"
                  v-model="item.item_description"
                  v-if="item.typing"
                />
                <multiselect
                  v-else
                  v-model="item.item_code"
                  id="ajax"
                  label="item_desc"
                  track-by="item_guid"
                  placeholder="Type to search"
                  open-direction="bottom"
                  :options="countries"
                  :multiple="false"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="100"
                  :limit-text="limitText"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="asyncFind"
                  @input="setItemVal()"
                >
                  <template slot="tag" slot-scope="{ option, remove }">
                    <span class="custom__tag">
                      <span>
                        {{ option.item_desc }}
                      </span>
                      <span class="custom__remove" @click="remove(option)">❌</span>
                    </span>
                  </template>
                  <template slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="item.item_code.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                  </template>
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
              </div>
            </div>
            <div class="col-md-2 col-12">
              <div class="form-group">
                <label for="item_quantity">Quantity</label>
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  required
                  :name="`item[${index}][item_quantity]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Quantity"
                  v-model="item.item_quantity"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Item Code</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_code]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Item Code"
                  v-model="item.item_code.item_code"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Barcode</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_barcode]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Item Code"
                  v-model="item.item_code.item_barcode"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Serial Number</label>
                <input
                readonly
                  type="text"
                  class="form-control"
                  required
                  :name="`item[${index}][item_serial_number]`"
                  aria-describedby="tpitem_quantity"
                  placeholder="Item Code"
                  v-model="item.item_code.item_serial_number"
                />
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="item_quantity">Item Photo</label>
                <img class="img img-fluid" v-if="item.item_code.item_photo" :src="`data:image/png;base64,${item.item_code.item_photo}`">
              </div>
            </div>
            <div class="col-md-3 col-12">
              <div class="form-group">
                <label for="name">Remark</label>
                <textarea
                  :name="`item[${index}][remark]`"
                  class="form-control"
                  id="description"
                  rows="5"
                  v-model="item.remark"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-12 pt-3">
          <div class="form-group">
            <button
              @click="addItem"
              class="add-btn btn btn-primary"
              type="button"
            >
              +</button
            ><button
              id="remove-btn-'+counter+'"
              class="remove-btn btn btn-danger"
              type="button"
              @click="removeItem(index)"
            >
              -
            </button>
          </div>
        </div>
        <input type="hidden" :name="`item[${index}][item_guid]`" v-model="item.item_code.item_guid">
        <input v-if="item.id" type="hidden" :name="`item[${index}][id]`" v-model="item.id">
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: ['orderitems','itemcode'],
  components: {
    //
  },
  data() {
    return {
      value: "",
      countries: [],
      options: [],
      isLoading: false,
      search_term: '',
      items: [
        {
          description: "",
          quantity: "",
          remark: "",
          item_code: [],
        },
      ],
    };
  },
  mounted() {
    console.log("Component mounted.");
    console.log(this.items);
    this.countries = this.itemcode 
    this.items = this.orderitems
  },
  methods: {
    addItem() {
      console.log('test')
      this.items.push({
        description: "",
        quantity: "",
        remark: "",
        item_code: [],
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    limitText (count) {
      return `and ${count} other countries`
    },
    asyncFind (query) {
      this.search_term = query
      if(query && query != ''){
        this.isLoading = true
        axios
          .get("/ajax/search-ac?parameter=" + query)
          .then((response) => {
            if(this.search_term == response.data.search_term){
              this.countries = response.data.data;
              this.isLoading = false
            }
          })
          .catch((e) => {
            console.log(e)
          });
      }
    },
    clearAll () {
      this.selectedCountries = []
    },
    setItemVal(){
      this.countries = []
    },
    retrieveItemCode() {
      axios
        .get("/ajax/search-ac?parameter=" + this.items[index].item_code)
        .then(({ data }) => {
          var a = [];
          for (var i = 0; i < data.length; i++) {
            var temp = { value: data[i]["id"], name: data[i]["name"] };
            a.push(temp);
          }
          this.customers = a;
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>